// import moment from 'moment';
// export default function formatDate(date: string) {
//   if (!date) return '';
//   return moment(date).format('YYYY-MM-DD');
// }
// global chip colors
export function chipColorClass (str) {
  if (!str) return ''
  switch (str.toLowerCase()) {
    case 'active':
      return 'success'
    case 'accepted':
      return 'success'
    case 'inactive':
      return 'error'
    case 'declined':
      return 'error'
    case 'rejected':
      return 'error'
    case 'closed':
      return 'error'
    case 'donor':
      return 'info'
    case 'exporter':
      return 'info'
    case 'export':
      return 'info'
    case 'recipient':
      return 'warning'
    case 'importer':
      return 'warning'
    case 'import':
      return 'warning'
    case 'donor & recipient':
      return 'secondary'
    case 'exporter & importer':
      return 'secondary'
    case 'hybrid':
      return 'purple white--text'
    case 'mandatory':
      return 'error'
    case 'informational':
      return 'info'
    case 'new':
      return 'primary'
    case 'open':
      return 'primary'
    case 'sent':
      return 'info'
    default:
      return ''
  }
}
